import React, { useContext, useEffect } from 'react';
import SiteContext from '../../context/Site/siteContext';
import Spinner from '../layout/Spinner';

import FooterForm from './FooterForm';
import FooterItem from './FooterItem';

const Footer = () => {
   const siteContext = useContext(SiteContext);
   const { footer, getFooter, loading } = siteContext;
   useEffect(() => {
      getFooter();
      // eslint-disable-next-line
   }, []);
   return (
      <div className='row'>
         <div className='col-12'>
            <h4>Подвал сайта</h4>
         </div>
         <div className='col-12'>
            <div className='row'>
               {loading ? (
                  <Spinner />
               ) : (
                  footer.map((item) => <FooterItem key={item.id} item={item} />)
               )}
            </div>
         </div>
         <div className='col-6'> {footer.length < 4 && <FooterForm />}</div>
      </div>
   );
};

export default Footer;
