import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import SiteContext from '../../context/Site/siteContext';

const HeaderItem = ({ item }) => {
   const siteContext = useContext(SiteContext);
   const { deleteHeader, updateHeader } = siteContext;

   const [data, setData] = useState({
      name: item.name,
      phone: item.phone,
   });

   const [text, setText] = useState('edit');
   const isEdit = text === 'edit' ? true : false;

   const onChange = (e) =>
      setData({ ...data, [e.target.name]: e.target.value });

   const onEdit = (e) => {
      if (!isEdit) {
         updateHeader(data);
         setText('edit');
      } else {
         setData(item);
         setText('save');
      }
   };

   const removeItem = () => deleteHeader(item.id);
   return (
      <div className='col-6'>
         <div className='form-row mb-2'>
            <div className='col-md-5'>
               <input
                  disabled={isEdit}
                  type='text'
                  name='name'
                  className='form-control form-control-sm'
                  onChange={onChange}
                  value={data.name}
               />
            </div>
            <div className='col-md-4'>
               <input
                  disabled={isEdit}
                  type='text'
                  name='phone'
                  className='form-control form-control-sm'
                  onChange={onChange}
                  value={data.phone}
               />
            </div>
            <div className='col-md-3'>
               <div className='d-flex'>
                  <button
                     onClick={onEdit}
                     className='btn btn-icon btn-success btn-sm mr-1'
                  >
                     <span className='material-icons'>{text}</span>
                  </button>
                  <button
                     type='button'
                     onClick={removeItem}
                     className='btn btn-icon btn-danger btn-sm'
                  >
                     <span className='material-icons'>delete</span>
                  </button>
               </div>
            </div>
         </div>
      </div>
   );
};
HeaderItem.defaultProps = {
   item: {
      name: 'a',
      phone: 'a',
   },
};
HeaderItem.propTypes = {
   item: PropTypes.object.isRequired,
};
export default HeaderItem;
