import {
   ADD_HEADER,
   UPDATE_HEADER,
   DELETE_HEADER,
   GET_HEADER,
   ADD_FOOTER,
   UPDATE_FOOTER,
   DELETE_FOOTER,
   GET_FOOTER,
} from '../types';

export default (state, action) => {
   switch (action.type) {
      case GET_HEADER:
         return {
            ...state,
            loading: false,
            header: action.payload,
         };
      case ADD_HEADER:
         return {
            ...state,
            header: [...state.header, action.payload],
         };
      case UPDATE_HEADER:
         return {
            ...state,
            header: [
               ...state.header.map((item) =>
                  item.id === action.payload.id ? action.payload : item
               ),
            ],
         };
      case DELETE_HEADER:
         const idx = state.header.findIndex(
            (item) => item.id === action.payload
         );
         return {
            ...state,
            header: [
               ...state.header.slice(0, idx),
               ...state.header.slice(idx + 1),
            ],
         };
      case GET_FOOTER:
         return {
            ...state,
            loading: false,
            footer: action.payload,
         };
      case ADD_FOOTER:
         return {
            ...state,
            footer: [...state.footer, action.payload],
         };
      case UPDATE_FOOTER:
         return {
            ...state,
            footer: [
               ...state.footer.map((item) =>
                  item.id === action.payload.id ? action.payload : item
               ),
            ],
         };
      case DELETE_FOOTER:
         const idxf = state.footer.findIndex(
            (item) => item.id === action.payload
         );
         return {
            ...state,
            footer: [
               ...state.footer.slice(0, idxf),
               ...state.footer.slice(idxf + 1),
            ],
         };
      default:
         return state;
   }
};
