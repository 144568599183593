import React, { useState, useContext } from 'react';
import SiteContext from '../../context/Site/siteContext';
const FooterForm = () => {
   const siteContext = useContext(SiteContext);

   const [item, setItem] = useState({
      name: '',
      text: '',
      map: '',
   });

   const onChange = (e) =>
      setItem({ ...item, [e.target.name]: e.target.value });

   const onSubmit = (e) => {
      e.preventDefault();
      siteContext.addFooter(item);
      setItem({
         name: '',
         text: '',
         map: '',
      });
   };
   return (
      <form onSubmit={onSubmit}>
         <div className='form-row'>
            <div className='col-12 mb-2'>
               <input
                  type='text'
                  name='name'
                  className='form-control form-control-sm'
                  placeholder='Метро'
                  required
                  onChange={onChange}
                  value={item.name}
               />
            </div>
            <div className='col-12 mb-2'>
               <textarea
                  type='text'
                  name='text'
                  className='form-control form-control-sm'
                  placeholder='Текст'
                  rows='4'
                  required
                  onChange={onChange}
                  value={item.text}
               ></textarea>
            </div>
            <div className='col-12 mb-2'>
               <input
                  type='text'
                  name='map'
                  className='form-control form-control-sm'
                  placeholder='Ссылка на карту'
                  onChange={onChange}
                  value={item.map}
               />
            </div>
            <div className='col-3 mb-2'>
               <button type='submit' className='btn btn-primary btn-sm'>
                  Добавить
               </button>
            </div>
         </div>
      </form>
   );
};

export default FooterForm;
