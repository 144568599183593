import React, { useReducer } from 'react';
import authReducer from './authReducer';
import AuthContext from './authContext';
import AuthService from '../../service/auth-service';

import {
   LOGIN_SUCCESS,
   LOGIN_FAIL,
   LOGOUT,
   CLEAR_ERRORS,
   AUTH_SUCCESS,
} from '../types';

const authService = new AuthService();

const AuthState = (props) => {
   const initionState = {
      token: localStorage.getItem('token'),
      isAuth: null,
      loading: true,
      error: null,
   };

   const [state, dispatch] = useReducer(authReducer, initionState);

   // Login User
   const checkAuth = async () => {
      try {
         const res = await authService.auth(state.token);
         if (res.error) {
            dispatch({ type: LOGIN_FAIL, payload: res.message });
            // checkAuth();
         } else {
            dispatch({ type: AUTH_SUCCESS });
         }
      } catch (error) {
         dispatch({ type: LOGIN_FAIL, payload: error });
      }
   };
   // Login User
   const login = async (user) => {
      try {
         const res = await authService.login(user);
         dispatch({ type: LOGIN_SUCCESS, payload: res.jwt });
      } catch (error) {
         dispatch({ type: LOGIN_FAIL, payload: error });
      }
   };

   // Logout
   const logout = () => dispatch({ type: LOGOUT });

   // Clear Errors
   const clearErrors = () => dispatch({ type: CLEAR_ERRORS });

   return (
      <AuthContext.Provider
         value={{
            token: state.token,
            isAuth: state.isAuth,
            loading: state.loading,
            error: state.error,
            login,
            logout,
            clearErrors,
            checkAuth,
         }}
      >
         {props.children}
      </AuthContext.Provider>
   );
};

export default AuthState;
