const pathApi = 'https://adm-api.oneservice.net.ua';

const headers = {
   'Content-Type': 'application/json',
   'X-Auth-Token': localStorage.getItem('token'),
};

export default class HeaderService {
   async getHeader() {
      return fetch(`${pathApi}/api/header.php`, {
         method: 'GET',
      }).then((res) => {
         return res.json();
      });
   }

   async addHeader(item) {
      let res = await fetch(`${pathApi}/api/header.php`, {
         method: 'POST',
         headers,
         body: JSON.stringify(item),
      })
         .then((res) => {
            return res.json();
         })
         .catch((err) => {
            throw new Error(err);
         });
      return res || [];
   }
   async updateHeader(item) {
      let res = await fetch(`${pathApi}/api/header.php`, {
         method: 'PUT',
         headers,
         body: JSON.stringify(item),
      })
         .then((res) => {
            return res.json();
         })
         .catch((err) => {
            throw new Error(err);
         });

      return res || [];
   }
   async deleteHeader(id) {
      let res = await fetch(`${pathApi}/api/header.php`, {
         method: 'delete',
         headers,
         body: JSON.stringify({ id }),
      })
         .then((res) => {
            return res.json();
         })
         .catch((err) => {
            throw new Error(err);
         });

      return res || [];
   }
}
