export const GET_HEADER = 'GET_HEADER';
export const ADD_HEADER = 'ADD_HEADER';
export const UPDATE_HEADER = 'UPDATE_HEADER';
export const DELETE_HEADER = 'DELETE_HEADER';
export const GET_FOOTER = 'GET_FOOTER';
export const ADD_FOOTER = 'ADD_FOOTER';
export const UPDATE_FOOTER = 'UPDATE_FOOTER';
export const DELETE_FOOTER = 'DELETE_FOOTER';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
