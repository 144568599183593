import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import SiteContext from '../../context/Site/siteContext';

const FooterItem = ({ item }) => {
   const siteContext = useContext(SiteContext);
   const { deleteFooter, updateFooter } = siteContext;

   const [data, setData] = useState({
      name: item.name,
      text: item.text,
      map: item.map,
   });

   const [text, setText] = useState('edit');
   const isEdit = text === 'edit' ? true : false;

   const onChange = (e) =>
      setData({ ...data, [e.target.name]: e.target.value });

   const onEdit = (e) => {
      if (!isEdit) {
         updateFooter(data);
         setText('edit');
      } else {
         setData(item);
         setText('save');
      }
   };

   const removeItem = () => deleteFooter(item.id);
   return (
      <div className='col-6'>
         <div className='form-row mb-4'>
            <div className='col-md-12 mb-2'>
               <input
                  disabled={isEdit}
                  type='text'
                  name='name'
                  className='form-control form-control-sm'
                  onChange={onChange}
                  value={data.name}
               />
            </div>
            <div className='col-md-12 mb-2'>
               <textarea
                  disabled={isEdit}
                  type='text'
                  rows='4'
                  name='text'
                  className='form-control form-control-sm'
                  onChange={onChange}
                  value={data.text}
               ></textarea>
            </div>
            <div className='col-md-12 mb-2'>
               <input
                  disabled={isEdit}
                  type='text'
                  name='map'
                  className='form-control form-control-sm'
                  onChange={onChange}
                  value={data.map}
               />
            </div>
            <div className='col-md-3'>
               <div className='d-flex'>
                  <button
                     onClick={onEdit}
                     className='btn btn-icon btn-success btn-sm mr-1'
                  >
                     <span className='material-icons'>{text}</span>
                  </button>
                  <button
                     type='button'
                     onClick={removeItem}
                     className='btn btn-icon btn-danger btn-sm'
                  >
                     <span className='material-icons'>delete</span>
                  </button>
               </div>
            </div>
         </div>
      </div>
   );
};

FooterItem.propTypes = {
   item: PropTypes.object.isRequired,
};
export default FooterItem;
