import React, { useContext, useState, useEffect } from 'react';
import AuthContext from '../../context/Auth/authContext';
import AlertContext from '../../context/alert/alertContext';

const Login = ({ history }) => {
   const alertContext = useContext(AlertContext);
   const authContext = useContext(AuthContext);
   const { login, isAuth } = authContext;

   useEffect(() => {
      if (isAuth) {
         history.push('/');
      }
   }, [isAuth, history]);

   const [user, setUser] = useState({
      login: '',
      password: '',
   });

   const onChange = (e) =>
      setUser({ ...user, [e.target.name]: e.target.value });

   const onSubmit = (e) => {
      e.preventDefault();
      if (user.login === '' || user.password === '') {
         alertContext.setAlert('Заполните поля', 'warning');
      } else {
         login(user);
      }
   };
   return (
      <form className='form-signin' onSubmit={onSubmit}>
         <h1 className='h3 mb-3 font-weight-normal'>Авторизация</h1>
         <label className='sr-only'>Логин</label>
         <input
            type='text'
            name='login'
            className='form-control'
            placeholder='Логин'
            onChange={onChange}
            value={user.login}
         />
         <label className='sr-only'>Пароль</label>
         <input
            type='password'
            name='password'
            className='form-control'
            placeholder='Пароль'
            onChange={onChange}
            value={user.password}
         />
         <button className='btn btn-lg btn-primary btn-block' type='submit'>
            Вход
         </button>
      </form>
   );
};

export default Login;
