import React from 'react';
import Header from '../header/Header';
import Footer from '../footer/Footer';

const Home = () => {
   return (
      <div className='container'>
         <Header />
         <hr />
         <Footer />
      </div>
   );
};

export default Home;
