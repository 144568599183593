const pathApi = 'https://adm-api.oneservice.net.ua';

export default class AuthService {
   async login(user) {
      let res = await fetch(`${pathApi}/api/login.php`, {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
         },
         body: JSON.stringify(user),
      })
         .then((res) => {
            return res.json();
         })
         .catch((err) => {
            throw new Error(err);
         });
      console.log('');
      return res || [];
   }
   async auth(token) {
      let res = await fetch(`${pathApi}/api/auth.php`, {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
         },
         body: JSON.stringify({ jwt: token }),
      })
         .then((res) => {
            return res.json();
         })
         .catch((err) => {
            throw new Error(err);
         });
      return res || [];
   }
}
