import React, { useReducer } from 'react';
import SiteContext from './siteContext';
import SiteReducer from './siteReducer';
import HeaderService from '../../service/header-service';
import FooterService from '../../service/footer-service';

import {
   ADD_HEADER,
   UPDATE_HEADER,
   DELETE_HEADER,
   GET_HEADER,
   ADD_FOOTER,
   UPDATE_FOOTER,
   DELETE_FOOTER,
   GET_FOOTER,
} from '../types';

const headerService = new HeaderService();
const footerService = new FooterService();

const SiteState = (props) => {
   const initionState = {
      header: [],
      footer: [],
      loading: true,
   };

   const [state, dispatch] = useReducer(SiteReducer, initionState);

   // Get item
   const getHeader = () => {
      headerService
         .getHeader()
         .then((res) => {
            dispatch({ type: GET_HEADER, payload: res });
         })
         .catch((error) => {
            console.log(error);
         });
   };

   // Add item
   const addHeader = (item) => {
      headerService
         .addHeader(item)
         .then((res) => {
            dispatch({ type: ADD_HEADER, payload: res });
         })
         .catch((error) => {
            console.log(error);
         });
   };

   // Update item
   const updateHeader = (item) => {
      headerService
         .updateHeader(item)
         .then((res) => {
            dispatch({ type: UPDATE_HEADER, payload: res });
         })
         .catch((error) => {
            console.log(error);
         });
   };

   // Delete item
   const deleteHeader = (id) => {
      headerService
         .deleteHeader(id)
         .then((res) => {
            dispatch({ type: DELETE_HEADER, payload: id });
         })
         .catch((error) => {
            console.log(error);
         });
   };
   // Get item
   const getFooter = () => {
      footerService
         .getFooter()
         .then((res) => {
            dispatch({ type: GET_FOOTER, payload: res });
         })
         .catch((error) => {
            console.log(error);
         });
   };

   // Add item
   const addFooter = (item) => {
      footerService
         .addFooter(item)
         .then((res) => {
            dispatch({ type: ADD_FOOTER, payload: res });
         })
         .catch((error) => {
            console.log(error);
         });
   };

   // Update item
   const updateFooter = (item) => {
      footerService
         .updateFooter(item)
         .then((res) => {
            dispatch({ type: UPDATE_FOOTER, payload: res });
         })
         .catch((error) => {
            console.log(error);
         });
   };

   // Delete item
   const deleteFooter = (id) => {
      footerService
         .deleteFooter(id)
         .then((res) => {
            dispatch({ type: DELETE_FOOTER, payload: id });
         })
         .catch((error) => {
            console.log(error);
         });
   };

   return (
      <SiteContext.Provider
         value={{
            header: state.header,
            footer: state.footer,
            loading: state.loading,
            getHeader,
            addHeader,
            updateHeader,
            deleteHeader,
            getFooter,
            addFooter,
            updateFooter,
            deleteFooter,
         }}
      >
         {props.children}
      </SiteContext.Provider>
   );
};

export default SiteState;
