import React, { useEffect, useContext } from 'react';
import AuthContext from '../../context/Auth/authContext';

const Navbar = () => {
   const authContext = useContext(AuthContext);
   const { logout, checkAuth, isAuth } = authContext;
   useEffect(() => {
      checkAuth();

      // eslint-disable-next-line
   }, []);

   const onLogout = () => {
      logout();
   };
   return (
      <nav className='navbar navbar-expand-md navbar-dark bg-dark mb-4 justify-content-between'>
         <a className='navbar-brand' href='#!'>
            OneService Admin
         </a>
         {isAuth && (
            <button onClick={onLogout} className='btn btn-light'>
               Выход
            </button>
         )}
      </nav>
   );
};

export default Navbar;
