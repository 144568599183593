import React, { useContext, useEffect } from 'react';
import SiteContext from '../../context/Site/siteContext';
import Spinner from '../layout/Spinner';

import HeaderForm from './HeaderForm';
import HeaderItem from './HeaderItem';

const Header = () => {
   const siteContext = useContext(SiteContext);
   const { header, getHeader, loading } = siteContext;
   useEffect(() => {
      getHeader();
      // eslint-disable-next-line
   }, []);
   return (
      <div className='row'>
         <div className='col-12'>
            <h4>Шапка сайта</h4>
         </div>
         <div className='col-12 mb-4'>
            <div className='row'>
               {loading ? (
                  <Spinner />
               ) : (
                  header.map((item) => <HeaderItem key={item.id} item={item} />)
               )}
            </div>
         </div>
         <div className='col-6'>{header.length < 4 && <HeaderForm />}</div>
      </div>
   );
};

export default Header;
