import {
   LOGIN_SUCCESS,
   LOGIN_FAIL,
   LOGOUT,
   CLEAR_ERRORS,
   AUTH_SUCCESS,
} from '../types';

export default (state, action) => {
   switch (action.type) {
      case AUTH_SUCCESS:
         return {
            ...state,
            loading: false,
            isAuth: true,
         };
      case LOGIN_SUCCESS:
         localStorage.setItem('token', action.payload);
         return {
            ...state,
            loading: false,
            isAuth: true,
            token: action.payload,
         };
      case LOGIN_FAIL:
      case LOGOUT:
         localStorage.removeItem('token');

         return {
            ...state,
            loading: false,
            isAuth: false,
            token: null,
            error: action.payload,
         };
      case CLEAR_ERRORS:
         return {
            ...state,
            error: null,
         };
      default:
         return state;
   }
};
