import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Login from './components/pages/Login';
import Home from './components/pages/Home';
import Navbar from './components/layout/Navbar';
import AuthState from './context/Auth/AuthState';
import SiteState from './context/Site/SiteState';
import AlertState from './context/alert/AlertState';
import PrivateRoute from './components/routing/PrivateRoute';
import Alerts from './components/layout/Alerts';

import './App.css';

function App() {
   return (
      <AuthState>
         <AlertState>
            <SiteState>
               <Router>
                  <Navbar />
                  <div className='container'>
                     <Alerts />
                  </div>
                  <Switch>
                     <PrivateRoute exact path='/' component={Home} />
                     <Route exact path='/login' component={Login} />
                  </Switch>
               </Router>
            </SiteState>
         </AlertState>
      </AuthState>
   );
}

export default App;
